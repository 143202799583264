<template>
  <v-dialog v-model="modal.toggle" persistent width="30%">
    <v-card>
      <v-container>
        <OrganizzeModalHeader
          :loading="modal.loading"
          :isEdit="true"
          :title="record.description"
          @onClose="closeModal()"
        />

        <v-menu
          transition="slide-y-transition"
          :close-on-content-click="true"
          offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dtPaidLabel"
              label="Data do pagamento"
              append-icon="mdi-calendar"
              style="margin-top: 16px;"
              v-bind="attrs"
              v-on="on"
              :disabled="modal.loading"
              readonly
              outlined>
            </v-text-field>
          </template>

          <v-date-picker
            v-model="record.dtPaid"
            no-title
            scrollable
            :min="record.dtEmission"
            :color="variables.colorPrimary">
          </v-date-picker>
        </v-menu>

        <v-currency-field
          label="Multa"
          v-model="record.interestValue"
          prefix="R$"
          :disabled="modal.loading"
          outlined
        />

        <v-currency-field
          label="Juros"
          v-model="record.fineValue"
          prefix="R$"
          :disabled="modal.loading"
          outlined
        />

        <OrganizzeModalFooter :loading="modal.loading" @onConfirm="enviar()"/>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import moment from 'moment'
import { mapActions } from 'vuex'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import { recordsTypesEnum } from '@/utils/tempUtils'
import { filter, map, flatten } from 'lodash'
import variables from '@/assets/styles/helpers/_variables.scss'

const namespaceStore = 'organizzeFinanceiroLancamentos'

export default {
  name: 'OrganizzeFinanceiroLancamentosModalCadastro',
  props: {},
  directives: {},
  components: {
    OrganizzeModalHeader: () => import('@/views/components/organizze/modal/header'),
    OrganizzeModalFooter: () => import('@/views/components/organizze/modal/footer')
  },
  data: () => ({
    modal: {
      toggle: false,
      loading: false
    },
    recordType: {
      id: null,
      name: 'Despesa',
      type: 'D'
    },
    record: {
      id: null,
      accountId: null,
      recordCategoryId: null,
      value: 0.0,
      status: 'P',
      type: null,
      description: '',
      memo: '',
      costCenterId: null,
      dtEmission: moment().format('YYYY-MM-DD'),
      dtDue: moment().format('YYYY-MM-DD'),
      dtPaid: moment().format('YYYY-MM-DD'),
      interestValue: 0.0,
      fineValue: 0.0,
      tags: [],
      attachments: []
    },
    dtPaidLabel: moment().format('DD/MM/YYYY'),
    selectedAttachments: []
  }),
  created () {},
  mounted () {
    const self = this

    Events.$on('modalEfetivarPagamento::open', val => {
      self.modal.toggle = true
      self.recordType = filter(recordsTypesEnum(), item => item.type === val.record.type)[0]
      self.record = {
        id: val.record.id,
        accountId: val.record.Accounts.id,
        recordCategoryId: val.record.RecordCategories.id,
        value: parseFloat(val.record.value).toFixed(2),
        status: 'E',
        type: val.record.type,
        description: val.record.description,
        memo: val.record.memo,
        costCenterId: (val.record.CostCenters || {}).id,
        dtEmission: moment(val.record.dtEmission).format('YYYY-MM-DD'),
        dtDue: moment(val.record.dtDue).format('YYYY-MM-DD'),
        dtPaid: moment(val.record.dtPaid || moment()).format('YYYY-MM-DD'),
        interestValue: val.record.interestValue,
        fineValue: val.record.fineValue,
        tags: (() => flatten(map(val.record.RecordTags, recordTag => map(recordTag.tag, tag => tag))))(),
        attachments: val.record.Attachments
      },
      self.dtPaidLabel = moment(val.record.dtPaid || moment()).format('DD/MM/YYYY'),
      self.selectedAttachments = self.record.attachments
    })
  },
  computed: {
    variables: () => variables
  },
  watch: {
    'record.dtPaid' () {
      const self = this
      self.dtPaidLabel = moment(self.record.dtPaid).format('DD/MM/YYYY')
    }
  },
  methods: {
    ...mapActions(namespaceStore, ['submit']),

    enviar () {
      const self = this

      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      self.modal.loading = true

      const data = {
        isEdit: true,
        record: self.record
      }

      self.submit(data).then(() => {
        successSnackbar('Lançamento editado com sucesso.')
        Events.$emit('OrganizzeFilter::buscar')
        self.closeModal()
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
       }).finally(() => {
        self.modal.loading = false
      })
    },

    closeModal () {
      const self = this
      self.modal = {
        toggle: false,
        loading: false
      }
      self.recordType = {
        id: null,
        name: '',
        type: null
      }
      self.record = {
        id: null,
        accountId: null,
        recordCategoryId: null,
        value: 0.0,
        status: 'P',
        type: null,
        description: '',
        memo: '',
        costCenterId: null,
        dtEmission: moment().format('YYYY-MM-DD'),
        dtDue: moment().format('YYYY-MM-DD'),
        dtPaid: moment().format('YYYY-MM-DD'),
        interestValue: 0.0,
        fineValue: 0.0,
        tags: [],
        attachments: []
      }
      self.dtPaidLabel = moment().format('DD/MM/YYYY'),
      self.selectedAttachments = []
    }
  },
  validations () { return {}}
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-card { border-radius: 16px !important; }
</style>